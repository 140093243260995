export const KeysightIcon = ({ ...props }) => (
	<svg
		width="261.1261"
		height="248.17519"
		viewBox="0 0 261.1261 248.17519"
		fill="none"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g transform="translate(33.063049,-52.418936)">
			<path
				d="m 214.21245,145.1108 c -1.78143,0 -5.74655,-2.24115 -8.44743,-12.87227 -4.36738,-17.23965 -6.60853,-31.4911 -14.02158,-31.4911 -7.41306,0 -11.03338,14.48131 -15.57316,31.54856 -2.58595,10.05647 -5.40176,12.87228 -9.8266,12.87228 -4.42484,0 -8.44743,-2.01129 -14.88357,-28.3305 -5.74655,-22.986202 -9.42434,-64.418834 -28.10063,-64.418834 -18.67629,0 -24.25045,54.189974 -26.664,64.648694 -2.93074,12.81481 -10.11393,28.04317 -19.36587,28.04317 -9.25195,0 -14.02159,-10.63112 -16.8374,-22.12422 -4.25245,-17.23965 -9.07955,-42.754337 -21.3197,-42.754337 -12.24016,0 -16.26274,20.917447 -21.6645,43.961117 C 14.28994,137.69775 9.5203,145.1108 0.26836,145.1108 h -19.48081 c 2.47895,1.24068 4.60517,3.08648 6.18208,5.36671 1.57691,2.27965 2.55365,4.92077 2.84,7.67796 3.04568,19.25095 5.40176,31.31871 12.24016,31.31871 6.83839,0 9.25195,-9.88407 13.21707,-26.08935 2.87327,-12.52748 7.64291,-18.27403 13.27453,-18.27403 8.44743,0 12.24015,10.05647 16.89486,29.53727 9.02208,38.5019 11.03338,57.46551 25.68708,57.46551 14.65371,0 16.8374,-18.67629 26.4916,-57.46551 4.19498,-16.66499 12.24015,-29.53727 21.4921,-29.53727 9.25195,0 15.80302,12.24016 19.25095,22.06676 7.24065,21.3197 10.63112,29.30741 18.67629,29.30741 8.04517,0 12.64241,-9.59674 19.6532,-32.92774 4.42485,-14.36638 5.22936,-18.44643 18.27403,-18.44643 z"
				fill="#e90029"
				style="stroke-width:5.74655"
			/>
			<g transform="matrix(2.0310034,0,0,2.0310034,-129.39354,204.51276)">
				<path
					d="M 59.45,47.02 50.82,37.1 v 9.92 H 47.43 V 26.69 h 3.39 v 8.84 l 8,-8.84 h 4.33 l -8.8,9.61 9.57,10.72 z"
					fill="#ffffff"
				/>
				<path
					d="m 77.57,26.69 v 3.25 h -8.62 v 4.78 h 7.68 v 3.25 h -7.68 v 5.8 h 8.62 v 3.25 H 65.56 V 26.69 Z"
					fill="#ffffff"
				/>
				<path
					d="m 82.92,26.69 5.13,8.07 5.07,-8.07 h 3.94 l -7.37,11.49 v 8.84 H 86.31 V 38.18 L 78.8,26.69 Z"
					fill="#ffffff"
				/>
				<path
					d="m 97.1,40.45 h 3.35 c 0,2.17 1.75,3.67 4.19,3.67 2.44,0 3.91,-1.15 3.91,-2.87 0,-4.57 -11.21,-1.64 -11.21,-9.08 0,-3.35 2.9,-5.76 6.88,-5.76 4.68,0 7.06,3 7.06,6.39 h -3.49 c -0.07,-1.81 -1.47,-3.18 -3.71,-3.18 -2.06,0 -3.42,1 -3.42,2.48 0,4.23 11.25,1.26 11.25,9.12 0,3.53 -3.11,6.08 -7.3,6.08 -4.19,0 -7.51,-2.87 -7.51,-6.85 z"
					fill="#ffffff"
				/>
				<path d="m 117.36,26.69 h -3.39 v 20.33 h 3.39 z" fill="#ffffff" />
				<path
					d="m 139.5,37.55 c 0.034,1.3007 -0.2,2.5945 -0.686,3.8013 -0.486,1.2069 -1.215,2.3011 -2.141,3.215 -0.926,0.9139 -2.03,1.6281 -3.243,2.0983 -1.213,0.4702 -2.51,0.6865 -3.81,0.6354 -1.37,0.0127 -2.729,-0.2513 -3.994,-0.7761 -1.266,-0.5249 -2.412,-1.2998 -3.371,-2.2783 -0.959,-0.9786 -1.711,-2.1406 -2.21,-3.4166 -0.499,-1.2759 -0.735,-2.6395 -0.695,-4.009 -0.041,-1.3676 0.196,-2.7294 0.696,-4.0031 0.499,-1.2738 1.251,-2.4333 2.211,-3.4088 0.959,-0.9754 2.106,-1.7465 3.372,-2.2669 1.265,-0.5204 2.623,-0.7793 3.991,-0.7612 4.57,0 8.69,3.1 9.43,6.67 h -3.85 c -0.76,-2 -3.17,-3.36 -5.58,-3.36 -0.917,0.0056 -1.823,0.1968 -2.664,0.5621 -0.841,0.3653 -1.599,0.8971 -2.229,1.5634 -0.63,0.6662 -1.118,1.4531 -1.435,2.3132 -0.318,0.8601 -0.458,1.7756 -0.412,2.6913 -0.04,0.9373 0.113,1.8729 0.448,2.749 0.336,0.8761 0.848,1.6741 1.503,2.345 0.656,0.6708 1.443,1.2002 2.311,1.5556 0.868,0.3553 1.8,0.529 2.738,0.5104 1.471,0.0349 2.906,-0.4614 4.041,-1.398 1.135,-0.9367 1.894,-2.2509 2.139,-3.702 h -6.35 V 35.6 h 9.67 c 0.105,0.6445 0.152,1.2971 0.14,1.95"
					fill="#ffffff"
				/>
				<path
					d="m 158.19,26.69 v 20.33 h -3.39 v -8.91 h -9.85 v 8.91 h -3.39 V 26.69 h 3.39 v 8.14 h 9.85 v -8.14 z"
					fill="#ffffff"
				/>
				<path
					d="m 176,29.94 h -6.25 v 17.08 h -3.39 V 29.94 h -6.25 V 26.69 H 176 Z"
					fill="#ffffff"
				/>
			</g>
		</g>
	</svg>
)
