export const NuSIMIcon = ({ ...props }) => (
	<svg
		version="1.1"
		width="529.90625"
		height="378.06836"
		viewBox="0 0 529.90625 378.06836"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g transform="translate(-72.013955,-5.1987305)">
			<path
				fill={'currentColor'}
				d="m 91.054965,5.1987305 c -10.5489,0 -19.04101,8.4921095 -19.04101,19.0410195 v 339.98437 c 0,10.54891 8.49211,19.04297 19.04101,19.04297 H 582.87919 c 10.54891,0 19.04102,-8.49406 19.04102,-19.04297 V 119.771 L 569.887,87.73779 334.68193,322.94482 211.33232,199.59521 a 33.448799,33.448799 0 0 1 0,-47.30273 33.448799,33.448799 0 0 1 47.30468,0 L 334.67997,228.33545 522.58232,40.43311 487.34794,5.1987305 Z"
			/>
		</g>
	</svg>
)
