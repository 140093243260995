export const SkyloIcon = ({ ...props }) => (
	<svg
		width="1.5397556mm"
		height="2.3607903mm"
		viewBox="0 0 1.5397556 2.3607903"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<defs id="defs1">
			<linearGradient
				id="paint0_linear_1111_12358"
				x1="5.7778301"
				y1="6.0551801"
				x2="25.3452"
				y2="7.3046198"
				gradientUnits="userSpaceOnUse"
				gradientTransform="matrix(0.07000434,0,0,0.07000434,112.83719,89.68339)"
			>
				<stop offset="0.0376609" stop-color="#19F3FF" id="stop3" />
				<stop offset="0.1" stop-color="#17F4EC" id="stop4" />
				<stop offset="0.31" stop-color="#12F6BC" id="stop5" />
				<stop offset="0.61" stop-color="#0BFA6F" id="stop6" />
				<stop offset="0.98" stop-color="#01FF05" id="stop7" />
				<stop offset="1" stop-color="#00FF00" id="stop8" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1111_12358"
				x1="12.4731"
				y1="0.147949"
				x2="25.155001"
				y2="0.95787603"
				gradientUnits="userSpaceOnUse"
				gradientTransform="matrix(0.07000434,0,0,0.07000434,112.83719,89.68339)"
			>
				<stop offset="0.0376609" stop-color="#19F3FF" id="stop9" />
				<stop offset="0.1" stop-color="#17F4EC" id="stop10" />
				<stop offset="0.31" stop-color="#12F6BC" id="stop11" />
				<stop offset="0.61" stop-color="#0BFA6F" id="stop12" />
				<stop offset="0.98" stop-color="#01FF05" id="stop13" />
				<stop offset="1" stop-color="#00FF00" id="stop14" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1111_12358"
				x1="6.6196299"
				y1="19.6299"
				x2="19.301201"
				y2="20.4398"
				gradientUnits="userSpaceOnUse"
				gradientTransform="matrix(0.07000434,0,0,0.07000434,112.83719,89.68339)"
			>
				<stop offset="0.0376609" stop-color="#19F3FF" id="stop15" />
				<stop offset="0.1" stop-color="#17F4EC" id="stop16" />
				<stop offset="0.31" stop-color="#12F6BC" id="stop17" />
				<stop offset="0.61" stop-color="#0BFA6F" id="stop18" />
				<stop offset="0.98" stop-color="#01FF05" id="stop19" />
				<stop offset="1" stop-color="#00FF00" id="stop20" />
			</linearGradient>
		</defs>
		<g id="layer1" transform="translate(-113.24163,-89.693741)">
			<path
				d="m 114.58879,91.645919 c -0.0506,-1.26e-4 -0.0991,-0.02033 -0.13482,-0.05618 l -1.15624,-1.155569 c -0.0359,-0.03592 -0.0561,-0.08464 -0.0561,-0.135427 1e-5,-0.05079 0.0202,-0.0995 0.0561,-0.135404 0.0359,-0.03591 0.0846,-0.05607 0.13543,-0.05606 0.0508,1.1e-5 0.0995,0.0202 0.13541,0.05612 l 1.15567,1.155566 c 0.0271,0.02669 0.0455,0.06084 0.0531,0.09808 0.008,0.03725 0.004,0.07589 -0.0108,0.110999 -0.0145,0.0351 -0.0392,0.06507 -0.0709,0.08606 -0.0317,0.021 -0.0689,0.03208 -0.10689,0.03181 z"
				fill="url(#paint0_linear_1111_12358)"
				id="path1"
				style="fill:url(#paint0_linear_1111_12358);stroke-width:0.0700042"
			/>
			<path
				d="m 114.51578,90.69078 c -0.0252,2.8e-5 -0.0501,-0.0049 -0.0734,-0.01456 -0.0233,-0.0096 -0.0444,-0.02379 -0.0621,-0.04162 l -0.6139,-0.614018 c -0.0359,-0.03594 -0.056,-0.08466 -0.056,-0.13545 3e-5,-0.05079 0.0202,-0.09949 0.0562,-0.135381 0.0359,-0.03589 0.0847,-0.05604 0.13546,-0.05601 0.0508,3.1e-5 0.0995,0.02024 0.13538,0.05617 l 0.6139,0.613906 c 0.027,0.02665 0.0455,0.06074 0.053,0.09792 0.008,0.03718 0.004,0.07577 -0.0106,0.110845 -0.0145,0.03508 -0.039,0.06505 -0.0706,0.0861 -0.0316,0.02105 -0.0687,0.03222 -0.10663,0.03209 z"
				fill="url(#paint1_linear_1111_12358)"
				id="path2"
				style="fill:url(#paint1_linear_1111_12358);stroke-width:0.0700042"
			/>
			<path
				d="m 114.10611,92.054191 c -0.0252,2.9e-5 -0.0501,-0.0049 -0.0734,-0.01457 -0.0233,-0.0096 -0.0444,-0.02378 -0.0621,-0.04161 l -0.61391,-0.613455 c -0.0359,-0.03593 -0.0561,-0.08464 -0.0561,-0.135444 0,-0.02515 0.005,-0.05006 0.0146,-0.0733 0.01,-0.02324 0.0237,-0.04435 0.0415,-0.06214 0.0178,-0.01779 0.0389,-0.03189 0.0621,-0.04152 0.0232,-0.0096 0.0481,-0.01458 0.0733,-0.01458 0.0508,0 0.0995,0.02018 0.13545,0.0561 l 0.6139,0.613903 c 0.027,0.02665 0.0455,0.06074 0.053,0.09792 0.008,0.03718 0.004,0.07577 -0.0106,0.110845 -0.0145,0.03508 -0.0391,0.06505 -0.0706,0.0861 -0.0316,0.02105 -0.0687,0.03222 -0.10662,0.03209 z"
				fill="url(#paint2_linear_1111_12358)"
				id="path3"
				style="fill:url(#paint2_linear_1111_12358);stroke-width:0.0700042"
			/>
		</g>
	</svg>
)
